import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Textarea,
    useToast,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "~/store";
import {addGallery, fetchGalleries} from "~/store/gallerySlice.ts";

interface GalleryProps {
    isVisible: boolean;
    onClose: () => void;
    isSelect?: boolean;
    onListSelect?: (publicId: string) => void;
}

interface Gallery {
    name: string;
    public_id: string;
    description?: string;
}

const Gallery: React.FC<GalleryProps> = ({
                                             isVisible,
                                             onClose,
                                             isSelect = false,
                                             onListSelect
                                         }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch<AppDispatch>();

    // Get galleries from Redux store
    const galleries = useSelector((state: RootState) => state.gallery.galleries);

    useEffect(() => {
        dispatch(fetchGalleries());
    }, []);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
        setNameError(value.length < 2);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setDescription(value);
        setDescriptionError(value.length < 20);
    };

    const handleSave = () => {
        const isNameValid = name.length >= 2;
        const isDescriptionValid = description.length >= 20;

        setNameError(!isNameValid);
        setDescriptionError(!isDescriptionValid);
        if(!isNameValid || !isDescriptionValid) {
            return;
        }

        dispatch(addGallery({ name, description })).unwrap().then((r) => {
            console.log(r);
            console.log('success');
            toast({
                title: "Gallery added",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        }).catch((e) => {
            console.log('error');
            console.log(e)
            toast({
                title: "Failed to add gallery",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    };

    const handleGallerySelect = (publicId: string) => {
        if (onListSelect) {
            onListSelect(publicId);
        }
    };

    if (isSelect) {
        return (
            <Menu>
                <MenuButton as={Button} colorScheme="gray.300">
                    Select Gallery
                </MenuButton>
                <MenuList bg="gray.800">
                    {galleries.filter(o => !o.is_collection).map((gallery: Gallery) => (
                        <MenuItem
                            bg="gray.800"
                            key={gallery.public_id}
                            onClick={() => handleGallerySelect(gallery.public_id)}
                        >
                            {gallery.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        );
    }

    return (
        <Modal isOpen={isVisible} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bgColor="gray.600" color="white">
                <ModalHeader>Add New Gallery</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isInvalid={nameError} mb={4}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            value={name}
                            onChange={handleNameChange}
                            placeholder="Enter gallery name"
                        />
                        {nameError && <FormErrorMessage>Name must be at least 2 characters long.</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={descriptionError}>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            value={description}
                            onChange={handleDescriptionChange}
                            placeholder="Enter gallery description"
                        />
                        {descriptionError && <FormErrorMessage>Description must be at least 20 characters long.</FormErrorMessage>}
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleSave}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default Gallery;