import { Box, Heading, Container } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import AiCategorySelect from "~/apps/backend/pages/AiCategorySelect.tsx";

const GenerateImages = () => {
    const navigate = useNavigate();

    const handleModelClick = (publicId?: string) => {
        if (!publicId) {
            return;
        }

        navigate(`/c-panel/generate-images/${publicId}`);
    };

    return (
        <Box bg="gray.800" color="primary.0" minH="100vh" p={4}>
            <Container maxW="container.xl">
                <Heading mb={8}>
                    AI Model Selection
                </Heading>

                <AiCategorySelect onClick={handleModelClick} />
            </Container>
        </Box>
    );
};

export default GenerateImages;