import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Box } from '@chakra-ui/react';
import { AppDispatch } from '~/store';
import { updateMaskLayer } from '~/store/imageSlice';
import MaskCanvas from './MaskCanvas';
import {Layer, Stroke} from "~/utils/types.ts";

interface Position {
    x: number;
    y: number;
}

interface Size {
    width: number;
    height: number;
}

interface UpdateData extends Partial<Position & Size> {
    public_id?: string;
}

interface ResizableLayerProps {
    layer: Layer;
    index: number;
    isActive: boolean;
    onUpdate: (updates: UpdateData) => void;
    children?: React.ReactNode;
    maxWidth?: number;
    maxHeight?: number;
}

const DraggableBox = forwardRef<HTMLDivElement, any>((props, ref) => {
    return <Box ref={ref} {...props} />;
});
DraggableBox.displayName = 'DraggableBox';

const ResizableLayer: React.FC<ResizableLayerProps> = ({
                                                           layer,
                                                           index,
                                                           isActive,
                                                           onUpdate,
                                                           maxWidth = 600,
                                                           maxHeight = 800
                                                       }) => {
    const dispatch = useDispatch<AppDispatch>();
    const contentRef = useRef<HTMLDivElement>(null);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const dimensionsRef = useRef<Size>({
        width: layer.width || maxWidth,
        height: layer.height || maxHeight
    });
    const aspectRatioRef = useRef<number>(1);
    const positionRef = useRef<Position>({ x: layer.x, y: layer.y });
    const initialized = useRef(false);
    const nodeRef = useRef(null);
    const startPositionRef = useRef({ x: 0, width: 0 });
    const [isDrawingMode, setIsDrawingMode] = useState(false);

    useEffect(() => {
        if (!initialized.current && contentRef.current) {
            const { offsetWidth, offsetHeight } = contentRef.current;
            let initialWidth, initialHeight, aspectRatio;

            if (!layer.width || !layer.height || (layer.width === 0 && layer.height === 0)) {
                initialWidth = maxWidth;
                initialHeight = maxWidth;
                aspectRatio = 1;

                if (initialHeight > maxHeight) {
                    initialHeight = maxHeight;
                    initialWidth = maxHeight;
                }
            } else {
                aspectRatio = offsetWidth / offsetHeight;
                initialWidth = Math.min(layer.width || offsetWidth, maxWidth);
                initialHeight = Math.min(initialWidth / aspectRatio, maxHeight);

                if (initialHeight === maxHeight) {
                    initialWidth = maxHeight * aspectRatio;
                }
            }

            dimensionsRef.current = {
                width: initialWidth,
                height: initialHeight
            };
            aspectRatioRef.current = aspectRatio;

            onUpdate({
                width: initialWidth,
                height: initialHeight,
                public_id: layer.public_id
            });

            initialized.current = true;
        }
    }, [layer, maxWidth, maxHeight, onUpdate]);

    const handleDrag = (_e: DraggableEvent, data: DraggableData): void => {
        if (isResizing) return;

        const newX = Math.max(0, Math.min(data.x, maxWidth - dimensionsRef.current.width));
        const newY = Math.max(0, Math.min(data.y, maxHeight - dimensionsRef.current.height));

        const newPosition = { x: newX, y: newY };
        positionRef.current = newPosition;
        onUpdate({
            ...newPosition,
            public_id: layer.public_id
        });
    };

    const startResize = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        startPositionRef.current = {
            x: e.clientX,
            width: dimensionsRef.current.width
        };

        setIsResizing(true);
    };

    const preventDragHandler = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (!isResizing) return;

            const deltaX = e.clientX - startPositionRef.current.x;
            const newWidth = Math.max(50, Math.min(startPositionRef.current.width + deltaX, maxWidth - layer.x));
            const newHeight = newWidth / aspectRatioRef.current;

            if (newHeight <= maxHeight - layer.y) {
                dimensionsRef.current = {
                    width: newWidth,
                    height: newHeight
                };

                onUpdate({
                    width: newWidth,
                    height: newHeight,
                    public_id: layer.public_id
                });
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
        };

        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, maxWidth, maxHeight, layer.x, layer.y, onUpdate, layer.public_id]);

    useEffect(() => {
        if (layer.width && layer.height) {
            dimensionsRef.current = {
                width: layer.width,
                height: layer.height
            };
        }
        positionRef.current = { x: layer.x, y: layer.y };
    }, [layer]);

    const renderContent = () => {
        if (layer.type === 'mask') {
            return (
                <MaskCanvas
                    isActive={isActive}
                    width={dimensionsRef.current.width}
                    height={dimensionsRef.current.height}
                    onDrawingModeChange={setIsDrawingMode}
                    savedStrokes={layer.strokes || []}
                    onSave={(maskData: string, strokes: Stroke[]) => {
                        if (layer.public_id) {
                            dispatch(updateMaskLayer({
                                public_id: layer.public_id,
                                maskData,
                                strokes
                            }));
                        }
                    }}
                />
            );
        } else if (layer.type === 'image') {
            return (
                <img
                    src={layer.other_values?.backgroundRemoved || layer.value}
                    alt={`Image Layer ${index}`}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }}
                />
            );
        }
        return <div style={layer.style as React.CSSProperties}>{layer.value}</div>;
    };

    const layerContent = (
        <Box
            width={dimensionsRef.current.width || 'auto'}
            height={dimensionsRef.current.height || 'auto'}
            ref={contentRef}
            position="relative"
        >
            {renderContent()}
            {isActive && (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    boxShadow="inset 0 0 0 2px #3182ce"
                    pointerEvents="none"
                />
            )}
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                onDragStart={preventDragHandler}
                style={{ userSelect: 'none' }}
            />
        </Box>
    );

    const position = { x: layer.x, y: layer.y };

    if (!isActive) {
        return (
            <Box
                position="absolute"
                top={position.y}
                left={position.x}
                zIndex={layer.z_index}
                style={{
                    opacity: layer.style?.opacity
                }}
            >
                {layerContent}
            </Box>
        );
    }

    return (
        <>
            {isResizing && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bg="blackAlpha.500"
                    zIndex={layer.z_index}
                    pointerEvents="none"
                />
            )}
            <Draggable
                nodeRef={nodeRef}
                onDrag={handleDrag}
                position={position}
                disabled={isResizing || isDrawingMode}
                bounds={{
                    left: 0,
                    top: 0,
                    right: maxWidth - dimensionsRef.current.width,
                    bottom: maxHeight - dimensionsRef.current.height
                }}
            >
                <DraggableBox
                    ref={nodeRef}
                    position="absolute"
                    zIndex={layer.z_index}
                    style={{
                        cursor: isResizing ? 'default' : 'move',
                        opacity: layer.style?.opacity,
                        userSelect: 'none'
                    }}
                >
                    <Box position="relative">
                        {layerContent}
                        <Box
                            position="absolute"
                            bottom={0}
                            right={0}
                            width="20px"
                            height="20px"
                            cursor="se-resize"
                            bg="blue.500"
                            opacity={0.8}
                            borderRadius="sm"
                            _hover={{ opacity: 1 }}
                            onMouseDown={startResize}
                        />
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            bg="blue.500"
                            color="white"
                            px={2}
                            py={1}
                            borderRadius="md"
                            fontSize="sm"
                        >
                            {layer.type === 'image' ? 'Image Layer' : layer.type === 'text' ? 'Text Layer' : 'Mask Layer'} {index + 1}
                        </Box>
                    </Box>
                </DraggableBox>
            </Draggable>
        </>
    );
};

export default ResizableLayer;