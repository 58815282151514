import React, {useRef, useState, useCallback, useEffect} from 'react';
import {
    Box,
    IconButton,
    Slider,
    HStack,
    Text,
    Tooltip,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Button
} from '@chakra-ui/react';
import { FaPaintBrush, FaEraser } from 'react-icons/fa';
import {Point} from "framer-motion";
import {Stroke} from "~/utils/types.ts";

interface MaskCanvasProps {
    isActive: boolean;
    width: number;
    height: number;
    onSave: (maskData: string, strokes: Stroke[]) => void;  // Updated this line
    onDrawingModeChange: (isDrawing: boolean) => void;
    savedStrokes?: Stroke[];
}

const MaskCanvas: React.FC<MaskCanvasProps> = (
    {
        isActive,
        width,
        height,
        onSave,
        onDrawingModeChange,
        savedStrokes = []
    }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const cursorCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isPainting, setIsPainting] = useState<boolean>(false);
    const [brushWidth, setBrushWidth] = useState<number>(25);
    const [isDrawingMode, setIsDrawingMode] = useState<boolean>(false);
    const [brushColor, setBrushColor] = useState<string>('#000000');
    const [tool, setTool] = useState<'brush' | 'eraser'>('brush');
    const lastPoint = useRef<{x: number, y: number} | null>(null);
    const canvasDataRef = useRef<string | null>(null);
    const [strokes, setStrokes] = useState<Stroke[]>(savedStrokes);
    const currentStroke = useRef<Stroke>({
        points: [],
        brushWidth: 25,
        color: '#000000',
        tool: 'brush'
    });

    // Restore canvas state when component becomes active
    useEffect(() => {
        if (isActive && canvasRef.current && canvasDataRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            const img = new Image();
            img.onload = () => {
                ctx?.drawImage(img, 0, 0);
            };
            img.src = canvasDataRef.current;
        }
    }, [isActive]);

    const drawLine = useCallback((ctx: CanvasRenderingContext2D, start: Point, end: Point, strokeConfig: Pick<Stroke, 'brushWidth' | 'color' | 'tool'>) => {
        const dx = end.x - start.x;
        const dy = end.y - start.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        ctx.globalCompositeOperation = strokeConfig.tool === 'eraser' ? 'destination-out' : 'source-over';

        for (let i = 0; i < distance; i += strokeConfig.brushWidth / 4) {
            const x = start.x + (dx * i) / distance;
            const y = start.y + (dy * i) / distance;
            ctx.beginPath();
            ctx.arc(x, y, strokeConfig.brushWidth/2, 0, Math.PI * 2);
            ctx.fillStyle = strokeConfig.tool === 'eraser' ? 'rgba(0,0,0,1)' : strokeConfig.color;
            ctx.fill();
        }
    }, []);

    const initializeAndReplayStrokes = useCallback((strokesToReplay: Stroke[]) => {
        if (!canvasRef.current) return;
        const ctx = canvasRef.current.getContext('2d');
        if (!ctx) return;

        // Clear and set initial state
        ctx.clearRect(0, 0, width, height);
        ctx.globalAlpha = 0.5;

        // Immediately replay strokes after clearing
        strokesToReplay.forEach(stroke => {
            for (let i = 1; i < stroke.points.length; i++) {
                drawLine(
                    ctx,
                    stroke.points[i - 1],
                    stroke.points[i],
                    {
                        brushWidth: stroke.brushWidth,
                        color: stroke.color,
                        tool: stroke.tool
                    }
                );
            }
        });
    }, [width, height, drawLine]);

    useEffect(() => {
        setStrokes(savedStrokes);
        initializeAndReplayStrokes(savedStrokes);
    }, [savedStrokes, initializeAndReplayStrokes]);

    const updateCursor = useCallback((e: MouseEvent) => {
        if (!cursorCanvasRef.current || !isDrawingMode) return;

        const ctx = cursorCanvasRef.current.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, width, height);

        const x = e.clientX - cursorCanvasRef.current.getBoundingClientRect().left;
        const y = e.clientY - cursorCanvasRef.current.getBoundingClientRect().top;

        ctx.beginPath();
        ctx.arc(x, y, brushWidth/2, 0, Math.PI * 2);
        if (tool === 'eraser') {
            ctx.strokeStyle = 'rgba(255,0,0,0.5)';
            ctx.fillStyle = 'rgba(255,0,0,0.5)';
            ctx.stroke();
            ctx.fill();
        } else {
            ctx.fillStyle = brushColor;
            ctx.fill();
        }
    }, [brushWidth, isDrawingMode, width, height, brushColor, tool]);

    useEffect(() => {
        if (isDrawingMode) {
            window.addEventListener('mousemove', updateCursor);
        }
        return () => window.removeEventListener('mousemove', updateCursor);
    }, [isDrawingMode, updateCursor]);

    const paint = useCallback((e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!isPainting || !isDrawingMode || !canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const rect = canvas.getBoundingClientRect();
        const currentPoint = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };

        if (lastPoint.current) {
            drawLine(ctx, lastPoint.current, currentPoint, {
                brushWidth,
                color: brushColor,
                tool
            });
            currentStroke.current.points.push(currentPoint);
        }

        lastPoint.current = currentPoint;
    }, [isPainting, isDrawingMode, drawLine, brushWidth, brushColor, tool]);

    const startPainting = useCallback((e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!isDrawingMode) return;
        setIsPainting(true);
        const rect = canvasRef.current?.getBoundingClientRect();
        if (rect) {
            const point = {
                x: e.clientX - rect.left,
                y: e.clientY - rect.top
            };
            lastPoint.current = point;
            currentStroke.current = {
                points: [point],
                brushWidth,
                color: brushColor,
                tool
            };
        }
        paint(e);
    }, [isDrawingMode, paint, brushWidth, brushColor, tool]);

    const stopPainting = useCallback(() => {
        if (isPainting) {
            setIsPainting(false);
            lastPoint.current = null;

            if (currentStroke.current.points.length > 0) {
                const newStrokes = [...strokes, currentStroke.current];
                setStrokes(newStrokes);
                initializeAndReplayStrokes(newStrokes);

                if (canvasRef.current) {
                    onSave(canvasRef.current.toDataURL(), newStrokes);
                }
            }
        }
    }, [isPainting, onSave, strokes, initializeAndReplayStrokes]);

    const toggleDrawingMode = () => {
        const newMode = !isDrawingMode;
        setIsDrawingMode(newMode);
        onDrawingModeChange(newMode);
    };

    return (
        <Box position="relative" style={{ boxShadow: 'none' }} zIndex={1}>
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                onMouseDown={startPainting}
                onMouseMove={paint}
                onMouseUp={stopPainting}
                onMouseLeave={stopPainting}
                style={{
                    border: isActive ? '2px solid #3182ce' : 'none',
                    cursor: 'none',
                    background: 'transparent'
                }}
            />
            <canvas
                ref={cursorCanvasRef}
                width={width}
                height={height}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    pointerEvents: 'none',
                    cursor: 'none'
                }}
            />
            {isActive && (
                <Box position="absolute" top={2} right={2} zIndex={200}>
                    <HStack spacing={2} bg="gray.800" p={2} borderRadius="md">
                        <Tooltip label={isDrawingMode ? "Disable Drawing" : "Enable Drawing"}>
                            <IconButton
                                icon={<FaPaintBrush />}
                                aria-label="Toggle Drawing"
                                colorScheme={isDrawingMode ? "blue" : "gray"}
                                onClick={toggleDrawingMode}
                            />
                        </Tooltip>
                        {isDrawingMode && (
                            <>
                                <HStack spacing={2}>
                                    <Tooltip label="Brush Tool">
                                        <IconButton
                                            icon={<FaPaintBrush />}
                                            aria-label="Brush"
                                            colorScheme={tool === 'brush' ? "blue" : "gray"}
                                            onClick={() => setTool('brush')}
                                        />
                                    </Tooltip>
                                    <Tooltip label="Eraser Tool">
                                        <IconButton
                                            icon={<FaEraser />}
                                            aria-label="Eraser"
                                            colorScheme={tool === 'eraser' ? "blue" : "gray"}
                                            onClick={() => setTool('eraser')}
                                        />
                                    </Tooltip>
                                </HStack>
                                {tool === 'brush' && (
                                    <Box w="150px">
                                        <HStack mb={2}>
                                            <Button
                                                size="xs"
                                                bg={brushColor === '#000000' ? 'gray.400' : 'gray.600'}
                                                onClick={() => setBrushColor('#000000')}
                                            >
                                                Black
                                            </Button>
                                            <Button
                                                size="xs"
                                                bg={brushColor === '#FFFFFF' ? 'gray.400' : 'gray.600'}
                                                onClick={() => setBrushColor('#FFFFFF')}
                                            >
                                                White
                                            </Button>
                                        </HStack>
                                        <Text fontSize="sm" color="white">Brush Size: {brushWidth}px</Text>
                                        <Slider
                                            min={1}
                                            max={50}
                                            value={brushWidth}
                                            onChange={(value: number) => setBrushWidth(value)}
                                        >
                                            <SliderTrack>
                                                <SliderFilledTrack />
                                            </SliderTrack>
                                            <SliderThumb />
                                        </Slider>
                                    </Box>
                                )}
                                {tool === 'eraser' && (
                                    <Box w="150px">
                                        <Text fontSize="sm" color="white">Eraser Size: {brushWidth}px</Text>
                                        <Slider
                                            min={1}
                                            max={50}
                                            value={brushWidth}
                                            onChange={(value: number) => setBrushWidth(value)}
                                        >
                                            <SliderTrack>
                                                <SliderFilledTrack />
                                            </SliderTrack>
                                            <SliderThumb />
                                        </Slider>
                                    </Box>
                                )}
                            </>
                        )}
                    </HStack>
                </Box>
            )}
        </Box>
    );
};

export default MaskCanvas;