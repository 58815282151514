import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Text,
    Textarea,
    Button,
    VStack,
    Alert,
    Image,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerCloseButton,
    HStack,
    useBreakpointValue, Link, Flex,
} from '@chakra-ui/react';
import { sendPrompt } from '~/store/imageSlice';
import {fetchCollectionDetail} from '~/store/collectionSlice';
import { RootState, AppDispatch } from '~/store';
import {ArrowForwardIcon, SettingsIcon} from "@chakra-ui/icons";
import {useNavigate, useParams} from "react-router-dom";
import { fetchBillingStatus, selectBillingStatus } from "~/store/billingSlice.ts";
import ModelSettings from "~/apps/backend/components/ModelSettings.tsx";
import BillingAlerts from "~/apps/backend/components/BillingAlerts.tsx";
import {useQuery} from "~/utils/react.ts";
import {BiArrowBack} from "react-icons/bi";
import Loader from "~/apps/backend/components/tools/Loader.tsx";

interface ChatMessage {
    type: 'system' | 'image';
    text: string;
    image_url?: string;
    public_id?: string;
}

const GenerateModelImage = () => {
    const { public_id } = useParams<{ public_id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const { loading: loading, currentCollection } = useSelector((state: RootState) => state.collections);
    const { loading: imagesLoading, images } = useSelector((state: RootState) => state.images);
    const navigate = useNavigate();
    const urlQuery = useQuery();
    const isBackground = urlQuery.get('background') === 'true';
    const  rmbckid = urlQuery.get('rmbckid');

    const [settings, setSettings] = useState({
        quality: 'Medium',
        aspectRatio: '1:1',
        prompt: '',
        no_of_images: '1',
    });
    const [chats, setChats] = useState<ChatMessage[]>([]);
    const chatEndRef = useRef<HTMLDivElement>(null);
    const billingStatus = useSelector(selectBillingStatus);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false); // State for Drawer

    const handleSettingChange = (key: string, value: string) => {
        setSettings({ ...settings, [key]: value });
    };

    useEffect(() => {
        if(public_id) {
            dispatch(
                fetchCollectionDetail(public_id)
            );
        }
    }, [public_id]);

    useEffect(() => {
        dispatch(fetchBillingStatus());
    }, [dispatch, images]);

    useEffect(() => {
        if (chatEndRef.current) {
            setTimeout(() => {
                chatEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 250);
        }

    }, [images]);

    useEffect(() => {
        if (images && images.length > 0) {
            const newImageChats = images.map((img) => ({
                type: 'image' as const,
                text: `Generated Image: ${img.prompt}`,
                image_url: img.image_url,
                public_id: img.public_id
            }));
            setChats((prevChats) => [...prevChats, ...newImageChats]);
        }
    }, [images]);

    const handleSendPrompt = async () => {
        if(!public_id) {
            return;
        }
        if (!currentCollection) {
            setChats((prev) => [...prev, { type: 'system', text: 'Please select a model.' }]);
            return;
        }

        if (currentCollection && !settings.prompt.includes(currentCollection.trigger_word) && currentCollection.model_type === 'custom') {
            setChats((prev) => [...prev, { type: 'system', text: `The prompt must include the trigger word: "${currentCollection.trigger_word}"` }]);
            return;
        }

        setChats((prev) => [...prev, { type: 'system', text: `You: ${settings.prompt}` }]);
        try {
            await dispatch(sendPrompt({ public_id: public_id, settings })).unwrap();
            handleSettingChange('prompt', '');
        } catch (error: any) {
            if (error.message.includes('code 403')) {
                navigate('/c-panel/billing?alt=lmti');
            } else {
                setChats((prev) => [...prev, { type: 'system', text: `Error: There was an error generating images, please try again or contact support.` }]);
                console.error('Failed to send prompt:', error.message || error);
            }
        }
    };

    const isSticky = useBreakpointValue({ base: true, md: false });

    if (loading || !currentCollection) {
        return (
            <Loader />
        )
    }
    return (
        <Box p={4} bg="gray.800" w="100%" h="100%" minH="100vh" display="flex" flexDirection="column">
            <Box
                maxW={{ base: '100%', md: '800px' }} // Full width on mobile, max width on larger screens
                w="100%" // Full width
                ml="auto"
                mr="auto"
                flex="1"
                display="flex"
                flexDirection="column"
                overflowY="auto"
                px={{ base: 2, md: 4 }} // Responsive padding
            >
                <Text fontSize="xl" fontWeight="bold" color="primary.0" textAlign="center" mb={2}>
                    {currentCollection.name.replace('Default - ', '')}
                </Text>

                {currentCollection.model_type === 'custom' ? (
                    <>
                        <Text color="primary.0" fontSize="sm" textAlign="center">
                            You are currently generating images from a trained model. Which means the images returned are
                            generated based on the images that were used to train the model.
                        </Text>
                    </>
                ) : (
                    <>
                        <Text color="primary.0" fontSize="sm" textAlign="center">
                            You are currently generating images using Flux models. Flux models are trained on a very large
                            dataset and can generate images based on a wide variety of prompts.
                        </Text>
                    </>
                )}

                <HStack
                    spacing={4}
                    align="center"
                    mb={2}
                    position={isSticky ? "sticky" : "static"}
                    top="0"
                    zIndex="2"
                    bg="gray.800"
                    p={2}
                >
                    <Box flex="1">
                        <Link
                            color="blue.400"
                            _hover={{ color: 'blue.200' }}
                            onClick={() => navigate('/c-panel/generate-images')}
                        >
                            <Flex align="center">
                                <BiArrowBack />
                                <Text ml={2}>Back to Models</Text>
                            </Flex>
                        </Link>
                    </Box>
                    <IconButton
                        aria-label="Open Settings"
                        icon={<SettingsIcon />}
                        colorScheme="gray.800"
                        onClick={() => setIsSettingsOpen(true)}
                    />
                </HStack>

                {billingStatus?.user && (
                    <Box position="relative">
                        <BillingAlerts billingStatus={billingStatus} top={0} />
                    </Box>
                )}

                {/* Drawer for Settings */}
                <Drawer
                    isOpen={isSettingsOpen}
                    placement="right"
                    onClose={() => setIsSettingsOpen(false)}
                >
                    <DrawerOverlay />
                    <DrawerContent bg="gray.800">
                        <DrawerCloseButton color={'white'} />
                        <DrawerHeader color="white">Settings</DrawerHeader>
                        <DrawerBody>
                            <ModelSettings
                                modelSettings={settings}
                                handleSettingChange={handleSettingChange}
                                modelType={currentCollection.model_type}
                            />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

                <VStack spacing={4} align="stretch" flex="1" overflowY="auto" mb={0}>
                    {/* Chat messages */}
                    <VStack spacing={4} align="stretch" flex="1" overflowY="auto" bg="gray.800" pl={4} pr={4}
                            borderRadius="md">
                        {chats.map((chat, index) => (
                            <Box key={index} w="100%">
                                {chat.type === 'system' && (
                                    <Alert status="info" borderRadius="md" bg="gray.700" color="primary.0">
                                        <Text>{chat.text}</Text>
                                    </Alert>
                                )}
                                {chat.type === 'image' && chat.image_url && (
                                    <Box borderRadius="md" p={4} shadow="md" borderWidth="1px" bg="gray.700" position="relative">
                                        <Text mb={2} color="primary.0">{chat.text}</Text>
                                        <Box position="relative" _hover={{ bg: "rgba(0, 0, 0, 0.5)" }}>
                                            <Image src={chat.image_url} alt="Generated Image" />
                                            <Button
                                                position="absolute"
                                                top={2}
                                                right={2}
                                                size="sm"
                                                colorScheme="blue"
                                                onClick={() => window.open(`/c-panel/modify-image/${chat.public_id}`, '_blank')}
                                            >
                                                Edit Image
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        ))}
                        <div ref={chatEndRef}></div>
                    </VStack>

                </VStack>

                {/* Prompt section */}
                <Box mt="auto" pl={4} pr={4} pb={0} bg="gray.800" w="100%">
                    {(currentCollection && currentCollection.model_type === 'custom') && (
                        <Text color="primary.0" mb={2}>
                            Prompt must include the trigger word: <strong>{currentCollection?.trigger_word}</strong>
                        </Text>
                    )}
                    {(currentCollection && currentCollection.model_type === 'flux') && (
                        <Text color="primary.0" mb={2}>
                            Flux models can be used to generate any image you can imagine. Just describe what you want to see!
                        </Text>
                    )}
                    {isBackground && (
                        <Text color="primary.0" mb={2}>
                            After you're happy with your background generation
                            <Link
                                ml={1}
                                mr={1}
                                color="blue.200" onClick={() => {navigate(`/c-panel/remove-background/${rmbckid}`)}}>
                                click here
                            </Link>to return to the background removal page.
                        </Text>
                    )}
                </Box>

                <Box mt="auto" pl={4} pr={4} bg="gray.800" w="100%" position="relative">
                    <Textarea
                        placeholder="Enter your prompt"
                        value={settings.prompt}
                        onChange={(e) => handleSettingChange('prompt', e.target.value)}
                        isDisabled={imagesLoading}
                        bg="gray.700"
                        color="primary.0"
                        _placeholder={{color: 'gray.400'}}
                        pr="60px"
                        resize="vertical"
                        w="100%"
                    />

                    <Button
                        colorScheme="blue"
                        onClick={handleSendPrompt}
                        isLoading={imagesLoading}
                        position="absolute"
                        right="10"
                        top="0"
                        bottom="0"
                        margin="auto"
                        borderRadius="full"
                        h="40px"
                        w="40px"
                        minW="40px"
                        p={0}
                        zIndex={3}
                    >
                        <ArrowForwardIcon boxSize={4}/>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default GenerateModelImage;
