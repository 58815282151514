import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollections, createCollection } from '~/store/collectionSlice';
import { RootState, AppDispatch } from '~/store';
import {
    Box, Heading, Text, Button, Input, Textarea,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    ModalCloseButton, useDisclosure, useToast, Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import AiCategorySelect from "~/apps/backend/pages/AiCategorySelect.tsx";

const Models = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { loading } = useSelector((state: RootState) => state.collections);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newName, setNewName] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [trainingType, setTrainingType] = useState('product');
    const toast = useToast();

    const trainingTypes = [
        { value: "product", label: "Product" },
        { value: "other", label: "Other" },
        { value: "ai influencer", label: "AI Influencer" },
    ];

    useEffect(() => {
        dispatch(fetchCollections({model_type: 'custom'}));
    }, [dispatch]);

    const handleModelClick = (public_id: string) => {
        navigate(`/c-panel/model/${public_id}`);
    };

    const handleAddModel = async () => {
        if (newName) {
            try {
                // Dispatch and unwrap to access the response data, including public_id
                const result = await dispatch(createCollection({ name: newName, description: newDescription, training_type:trainingType })).unwrap();

                // Navigate to the new model's detail page
                navigate(`/c-panel/model/${result.public_id}`);

                // Clear input fields and close modal
                setNewName('');
                setNewDescription('');
                onClose();
            } catch (error) {
                if ((error as Error).message.includes("code 403")) {
                    // Show specific error message for 403 error
                    navigate('/c-panel/billing?alt=lmtc');
                } else {
                    // Show a generic error message
                    toast({
                        title: "An error occurred",
                        description: "Failed to create model. Please try again later.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        }
    };

    return (
        <Box p={6} bg="gray.800" minH="100vh">
            <Heading as="h1" size="xl" mb={6}>Models</Heading>

            {loading ? <Text>Loading...</Text> : null}

            <Button colorScheme="blue" mb={6} onClick={onOpen}>Add Model</Button>

            <AiCategorySelect onClick={handleModelClick} categoryFilter={'custom'} showTitle={false} />


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bgColor="gray.800">
                    <ModalHeader color='primary.0'>Add New Model</ModalHeader>
                    <ModalCloseButton  color='primary.0' />
                    <ModalBody>
                        <Input
                            color='primary.0'
                            placeholder="Model Name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            mb={3}
                        />
                        <Menu >
                            <MenuButton
                                bg="gray.800"
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                w="100%"
                                border="1px solid"
                                borderColor="primary.0"
                                mb={3}
                                textAlign="left"
                                color="primary.0"
                                _hover={{
                                    bg: 'gray.700'
                                }}
                                _active={{
                                    bg: 'gray.700'
                                }}
                            >
                                {trainingTypes.find(type => type.value === trainingType)?.label || 'Select Type'}
                            </MenuButton>
                            <MenuList bg="gray.700">
                                {trainingTypes.map((type) => (
                                    <MenuItem
                                        key={type.value}
                                        onClick={() => setTrainingType(type.value)}
                                        bg="gray.700"
                                        _hover={{ bg: 'gray.600' }}
                                        color="primary.0"
                                    >
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        <Textarea
                            color='primary.0'
                            placeholder="Description"
                            value={newDescription}
                            onChange={(e) => setNewDescription(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleAddModel} isDisabled={!newName}>
                            Create Model
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Models;
