import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import {
    Flex,
    Box,
    Button,
    Image,
    VStack,
    Icon,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Link,
    useToast,
    useBreakpointValue,
    Spinner
} from '@chakra-ui/react';
import cti from '../../../assets/chair-table.jpg';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "~/store/authSlice.ts";
import { AppDispatch, RootState } from "~/store";
import { useEffect, useState } from "react";
import GoogleLoginButton from "~/apps/auth/components/GoogleLoginButton.tsx";
import ReCAPTCHA from "react-google-recaptcha";

interface FormData {
    username: string;
    password: string;
}

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [isLoading, setIsLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('at') === 'expired') {
            toast({
                title: 'Session Expired',
                description: 'Your session has expired. Please re-authenticate.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });

            params.delete('at');
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });
        }
    }, [location, toast]);

    const token = useSelector((state: RootState) => state.auth.token);

    useEffect(() => {
        if (token) {
            navigate('/c-panel/generate-images');
        }
    }, [token, navigate]);

    const onSubmit = (data: FormData) => {
        setIsLoading(true);
        try {
            dispatch(
                login(
                    { username: data.username, password: data.password, captcha_token: captchaToken }
                )
            ).unwrap().then(() => {
                navigate('/c-panel/generate-images');
                setIsLoading(false);
            }).catch((e) => {
                toast({
                    title: 'Login Failed',
                    description: e.message || 'An error occurred while logging in.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                setIsLoading(false);
            });

        } catch (error: any) {
            toast({
                title: 'Login Failed',
                description: error.message || 'An error occurred while logging in.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            setIsLoading(false);
        }
    };

    const handleCaptchaChange = (token: string | null): void => {
        setCaptchaToken(token || '');
    };

    const handleReturnHome = () => {
        window.location.href = 'https://cartario.com';
    };

    return (
        <Flex height="100vh" alignItems="stretch" animation="fadeIn 2s ease-in-out forwards">
            {isMobile ? (
                <Box
                    position="relative"
                    flex="1"
                    bgImage={`url(${cti})`}
                    bgSize="cover"
                    bgPosition="center"
                    width="100%"
                    height="100%"
                >
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        bg="rgba(0, 0, 0, 0.5)"
                    />
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        p={6}
                        height="100%"
                        position="relative"
                        zIndex={1}
                    >
                        <VStack width="90%" spacing={5} align="stretch">
                            <Button
                                onClick={handleReturnHome}
                                leftIcon={<Icon as={IoArrowBackCircleSharp} w={8} h={8} />}
                                variant="outline"
                                color="white"
                                borderRadius={0}
                                justifyContent="flex-start"
                                fontSize="xl"
                                iconSpacing={2}
                                _hover={{ bg: "whiteAlpha.300" }}
                            >
                                Return Home
                            </Button>

                            {isLoading ? (
                                <Spinner size="xl" color="white" />
                            ) : (
                                <GoogleLoginButton />
                            )}

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormControl isInvalid={Boolean(errors.username)} mb={4}>
                                    <FormLabel color="white" fontSize="lg">Username</FormLabel>
                                    <Input
                                        type="text"
                                        {...register('username', {
                                            required: 'Username is required',
                                            minLength: {
                                                value: 6,
                                                message: 'Username must be at least 6 characters long',
                                            }
                                        })}
                                        bg="white"
                                    />
                                    <FormErrorMessage color="white" fontSize="lg">
                                        {errors.username ? (errors.username.message as string) : null}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={Boolean(errors.password)} mb={4}>
                                    <FormLabel color="white" fontSize="lg">Password</FormLabel>
                                    <Input
                                        type="password"
                                        {...register('password', { required: 'Password is required' })}
                                        bg="white"
                                    />
                                    <FormErrorMessage color="white" fontSize="lg">
                                        {errors.password ? (errors.password.message as string) : null}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl isRequired>
                                    <Box w="100%">
                                        <ReCAPTCHA
                                            sitekey={RECAPTCHA_SITE_KEY}
                                            onChange={handleCaptchaChange}
                                        />
                                    </Box>
                                </FormControl>

                                <Button mt={4}
                                        disabled={!captchaToken}
                                        width="full" size="lg" type="submit" colorScheme="whiteAlpha" variant="solid">
                                    Login
                                </Button>
                            </form>

                            <Link as={RouterLink} color="white" to="/forgot-password" textAlign="center">Forgot password?</Link>
                            <Link as={RouterLink} color="white" to="/register" textAlign="center">Register</Link>
                        </VStack>
                    </Flex>
                </Box>
            ) : (
                <>
                    <Box flex="1">
                        <Image src={cti} alt="Login Image" objectFit="cover" width="100%" height="100%" />
                    </Box>
                    <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="center" p={10} bg="white">
                        <VStack width={{ 'base': '80%', 'lg': '60%', 'md': '50%' }} spacing={5} align="stretch">
                            <Box position="absolute" top={15} right={15}>
                                <Button
                                    onClick={handleReturnHome}
                                    leftIcon={<Icon as={IoArrowBackCircleSharp} w={8} h={8} />}
                                    variant="outline"
                                    borderColor="gray.300"
                                    color="black"
                                    size='xxl'
                                    border={0}
                                    borderRadius={0}
                                    justifyContent="flex-start"
                                    fontSize="xl"
                                    iconSpacing={2}
                                    _hover={{ bg: "gray.100" }}>
                                    Return Home
                                </Button>
                            </Box>

                            {isLoading ? (
                                <Spinner size="xl" color="black" />
                            ) : (
                                <GoogleLoginButton />
                            )}

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormControl isInvalid={Boolean(errors.username)} mb={4}>
                                    <FormLabel fontSize="lg">Username</FormLabel>
                                    <Input
                                        type="text"
                                        {...register('username', {
                                            required: 'Username is required',
                                            minLength: {
                                                value: 6,
                                                message: 'Username must be at least 6 characters long',
                                            }
                                        })}
                                    />
                                    <FormErrorMessage fontSize="lg">
                                        {errors.username ? (errors.username.message as string) : null}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={Boolean(errors.password)} mb={4}>
                                    <FormLabel fontSize="lg">Password</FormLabel>
                                    <Input
                                        type="password"
                                        {...register('password', { required: 'Password is required' })}
                                    />
                                    <FormErrorMessage fontSize="lg">
                                        {errors.password ? (errors.password.message as string) : null}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl isRequired>
                                    <Box w="100%">
                                        <ReCAPTCHA
                                            sitekey={RECAPTCHA_SITE_KEY}
                                            onChange={handleCaptchaChange}
                                        />
                                    </Box>
                                </FormControl>

                                <Button mt={4} width="full" size="lg" type="submit" colorScheme="blackAlpha"
                                        disabled={!captchaToken}
                                        variant="solid">Login</Button>
                            </form>

                            <Link as={RouterLink} color="black" to="/forgot-password" textAlign="center">Forgot password?</Link>
                            <Link as={RouterLink} color="black" to="/register" textAlign="center">Register</Link>
                        </VStack>
                    </Flex>
                </>
            )}
        </Flex>
    );
};

export default Login;