import {Center} from "@chakra-ui/icons";
import {Box, Spinner, Text} from "@chakra-ui/react";

const Loader = () => {
    return (
        <Box p={4} bg="gray.800" w="100%" h="100%" minH="100vh">
            <Center
                position="fixed"
                inset={0}
                bg="blackAlpha.700"
                backdropFilter="blur(8px)"
                zIndex={9999}
                flexDirection="column"
                gap={4}
            >
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.600"
                    color="primary.0"
                    size="xl"
                />
                <Text color="primary.0" fontSize="xl">
                    Loading...
                </Text>
            </Center>
        </Box>
    );
}

export default Loader;