import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '~/store';
import {
    activateMaskLayer,
    addImageEffect,
    addLayer,
    getImage,
    mergeLayers,
    modifyLayer,
    removeBackgroundFromLayer, removeLayer,
    resetLayerPositions, saveImage
} from '~/store/imageSlice.ts';
import {
    useToast,
    Box,
    Flex,
    IconButton,
    VStack,
    HStack,
    Tooltip,
    Text,
    Modal,
    ModalContent,
    useDisclosure,
    Button,
    ModalCloseButton,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    ModalOverlay,
    ModalHeader, ModalBody, Textarea, ModalFooter,
} from '@chakra-ui/react';
import {FaArrowAltCircleUp, FaImage, FaMagic, FaMask, FaSave, FaTextHeight} from 'react-icons/fa';
import GallerySelect from "~/apps/backend/components/GallerySelect.tsx";
import {GalleryImage, Layer} from "~/utils/types.ts";
import LayerToolbar from "~/apps/backend/components/tools/LayerToolbar.tsx";
import ResizableLayer from "~/apps/backend/components/tools/ResizableLayer.tsx";
import {MdMerge} from "react-icons/md";
import {BiRefresh} from "react-icons/bi";
import {LuCloudFog} from "react-icons/lu";
import {Center, DeleteIcon} from "@chakra-ui/icons";
import {HiRefresh} from "react-icons/hi";
import Gallery from "~/apps/backend/components/modals/Gallery.tsx";
import {downloadImage} from "~/store/gallerySlice.ts";

const ModifyImage = () => {
    const { public_id } = useParams<{ public_id: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const image = useSelector((state: RootState) => state.images.selectedImage);
    const loading = useSelector((state: RootState) => state.images.loading);
    const [activeLayer, setActiveLayer] = useState<Layer | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen:isSaveOpen, onOpen:onSaveOpen, onClose:onSaveClose } = useDisclosure();
    const { isOpen:isMaskActivateOpen, onOpen:onMaskActivateOpen, onClose:onMaskClose } = useDisclosure();
    const [maskPrompt, setMaskPrompt] = useState('');
    const defaultCanvasSize = 600;
    const defaultCanvasHeight = 600;

    const checkLayerProperty = (property: string) => {
        return image?.layers?.length === 1 && image.layers[0].other_values?.[property] === true;
    };

    const isMerged = checkLayerProperty('merged');
    const enhancedShadows = checkLayerProperty('enhanced_shadows');
    const enhancedLighting = checkLayerProperty('enhanced_lighting');
    const [hasMask, setHasMask] = useState(false);

    useEffect(() => {
        if (!image || !image.layers) {
            return;
        }
        const checkHasMask = image.layers.filter(layer => layer.type === 'mask').length > 0
        setHasMask(checkHasMask);
        if(checkHasMask) {
            const maskLayer = image.layers.find(layer => layer.type === 'mask');
            if(!maskLayer) {
                return;
            }

            setActiveLayer(maskLayer);
        }

    }, [image]);
    useEffect(() => {
        if (!public_id) {
            navigate('/c-panel/modify-image');
            return;
        }
        dispatch(getImage(public_id)).unwrap().catch((_) => {
            toast({
                title: "Failed to fetch image",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }, [public_id, dispatch, navigate, toast]);

    const handleSave = (gallery_public_id: string) => {
        const image_public_id = image?.public_id;
        if (!image_public_id) {
            return;
        }

        dispatch(saveImage({ image_public_id,gallery_public_id})).unwrap().then(() => {
            onSaveClose();
            navigate('/c-panel/my-images');
        }).catch(() => {
            toast({
                title: "Failed to save image",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });


    };

    const handleAddLayer = (type: string, value: string) => {
        if(!image || loading) {
            return;
        }

        if(type === 'image' && !value) {
            onOpen();
            return;
        }

        const layer = {
            type: type,
            value: value,
            x: 0,
            y: 0,
            style: {},
            status: 'active',
            z_index: 1,
            width: 0,
            height: 0,
            other_values: {}
        }

        // First deactivate current layer
        setActiveLayer(null);

        dispatch(addLayer({ public_id: image.public_id, layer })).unwrap().then((response) => {
            // Set the newly added layer as active using the response from the server
            setActiveLayer(response);
            toast({
                title: "Layer added",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }).catch((e) => {
            console.log(e);
            toast({
                title: "Failed to add layer",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    };

    const imageSelected = (image: GalleryImage) => {
        // First clear any active layer
        setActiveLayer(null);
        handleAddLayer('image', image.image_url);
        onClose();
    }

    const updateLayer = (params: any) => {
        if (!activeLayer) return;

        const updatedLayer = {
            ...activeLayer,
            ...params
        };

        dispatch(modifyLayer({
            params: params,
            layer: activeLayer,
        }));

        setActiveLayer(updatedLayer);
    };

    const deleteLayer = async (public_id: string) => {
        if(!confirm('Are you sure you want to delete this layer, this action can not be undone?')) {
            return;
        }

        try {
            // Clear active layer if we're deleting it
            if (activeLayer?.public_id === public_id) {
                setActiveLayer(null);
            }

            await dispatch(removeLayer({public_id: public_id})).unwrap();

            toast({
                title: "Layer deleted",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            toast({
                title: "Failed to delete layer",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const handleMaskActivation = () => {
        if (!maskPrompt.trim() || !activeLayer?.public_id) {
            toast({
                title: "Please enter a transformation instruction",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        dispatch(activateMaskLayer({
            public_id: activeLayer.public_id,
            prompt: maskPrompt.trim()
        })).unwrap()
            .then(() => {
                onMaskClose();
                setMaskPrompt('');
                setActiveLayer(null);
                toast({
                    title: "Mask transformation applied",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                location.reload();
            })
            .catch(() => {
                toast({
                    title: "Failed to apply mask transformation",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    // Add effect to update activeLayer when image changes
    useEffect(() => {
        if (activeLayer) {
            // Check if the active layer still exists in the current image
            const layerExists = image?.layers?.find(layer => layer.public_id === activeLayer.public_id);
            if (!layerExists) {
                setActiveLayer(null);
            }
        }
    }, [image, activeLayer]);

    const cloneLayer = async (layerId: string) => {
        if (!image || loading) {
            return;
        }

        // Find the layer to clone
        const layerToClone = image.layers.find(layer => layer.public_id === layerId);
        if (!layerToClone) {
            return;
        }

        // Create new layer object with same properties
        const newLayer: Layer = {
            type: layerToClone.type,
            value: layerToClone.value,
            x: layerToClone.x,
            y: layerToClone.y,
            style: { ...layerToClone.style },
            status: 'active',
            z_index: layerToClone.z_index,
            width: layerToClone.width,
            height: layerToClone.height,
            other_values: { ...layerToClone.other_values }
        };

        try {
            // First deactivate current layer
            setActiveLayer(null);

            const response = await dispatch(addLayer({
                public_id: image.public_id,
                layer: newLayer
            })).unwrap();

            // Set the newly cloned layer as active
            if (response.layers && response.layers.length > 0) {
                const newLayer = response.layers[response.layers.length - 1];
                setActiveLayer(newLayer);
            }

            toast({
                title: "Layer cloned successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Failed to clone layer",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const removeBackground = (type: string, layer: Layer) => {
        console.log(type);
        console.log(layer);
        const payload = {
            public_id: layer.public_id || '',
            type: type,
        }
        dispatch(removeBackgroundFromLayer(payload)).unwrap().then((response) => {
            console.log(response);
            toast({
                title: "Background removed",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }).catch(() => {
            toast({
                title: "Failed to remove background",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    const handleMergeLayers = () => {
        if(!confirm('Are you sure you want to merge layers?')) {
            return;
        }
        if(!image || loading) {
            return;
        }

        console.log(image.layers);
        // return;

        dispatch(mergeLayers({
            public_id: image.public_id,
            layers: image.layers
        })).unwrap().then(() => {
            toast({
                title: "Layers merged",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            location.reload();
        }).catch(() => {
            toast({
                title: "Failed to merge layers",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    const resetLayers = () => {
        dispatch(resetLayerPositions())
    }

    const alterEffect = (effect: string) => {
        if (!image || image.layers.length === 0) {
            return;
        }
        const public_id = image.layers[0].public_id;
        if(typeof public_id === 'undefined') {
            return;
        }
        if(effect === 'original') {
            if(!confirm('Are you sure you want to reset back to original?')) {
                return;
            }
        }
        dispatch(addImageEffect({public_id: public_id, effect: effect})).unwrap().then(() => {
            toast({
                title: "Effect added",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            if(effect === 'original') {
                // location.reload
                location.reload();
            }
        }).catch(() => {
            toast({
                title: "Failed to add effect",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    const handleDownload = async () => {
        console.log('Download image');
        if (!image) {
            return;
        }
        console.log(image);
        const image_puid = image.layers[0].public_id;
        if(!image_puid) {
            return;
        }

        dispatch(downloadImage({
            publicId: image_puid,
            imageType: 'layer',
        })).unwrap().then(() => {
            toast({
                title: "Image downloaded",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }).catch(() => {
            toast({
                title: "Failed to download image",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });

    }


    return (
        <Box p={4} bg="gray.800" minH="100vh" minW="1024px">
            {loading && (
                <Center
                    position="fixed"
                    inset={0}
                    bg="blackAlpha.700"
                    backdropFilter="blur(8px)"
                    zIndex={9999}
                    flexDirection="column"
                    gap={4}
                >
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.600"
                        color="primary.0"
                        size="xl"
                    />
                    <Text color="primary.0" fontSize="xl">
                        Loading...
                    </Text>
                </Center>
            )}
            <Flex>
                <Box flex="1">
                    <Box
                        flex="1"
                        position="relative"
                        width={`${defaultCanvasSize}px`}
                        height={`${defaultCanvasHeight}px`}
                        mx="auto"
                        bg="gray.900"
                        overflow="hidden"
                    >
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                        >
                            {image && image.layers && image.layers.map((layer: Layer, index: number) => (
                                <ResizableLayer
                                    key={`${layer.public_id}-${index}`}
                                    layer={layer}
                                    index={index}
                                    isActive={activeLayer?.public_id === layer.public_id}
                                    onUpdate={updateLayer}
                                    maxWidth={defaultCanvasSize}
                                    maxHeight={defaultCanvasHeight}
                                >
                                    {layer.type === 'image' ? (
                                        <img
                                            src={layer.other_values?.backgroundRemoved || layer.value}
                                            alt={`Image Layer ${index}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    ) : (
                                        <div style={layer.style as React.CSSProperties}>{layer.value}</div>
                                    )}
                                </ResizableLayer>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box w="300px" bg="gray.700" p={4}>
                    {(!isMerged && !hasMask) && (
                        <Text mb={4}>
                            Before you can save / color correct you must first merge all layers.
                        </Text>
                    )}

                    {hasMask && (
                        <Alert>
                            <AlertIcon />
                            <AlertDescription color={'blue.400'}>
                                You must finish adding your mask and save the image before you select other layers.
                            </AlertDescription>
                        </Alert>
                    )}

                    <VStack spacing={4}>
                        <HStack spacing={4}>

                            {(enhancedLighting || enhancedShadows) && (
                                <Tooltip label="Reset Color & Shadows">
                                    <IconButton
                                        aria-label="Reset Color & Shadows"
                                        icon={<HiRefresh />}
                                        size="lg"
                                        variant="ghost"
                                        color="primary.0"
                                        onClick={() => alterEffect('reset')}
                                        _hover={{ opacity: 0.8 }}
                                        transition="opacity 0.2s"
                                    />
                                </Tooltip>
                            )}

                            {(!enhancedLighting && isMerged) && (
                                <>
                                    <Tooltip label="Auto Correct Colors">
                                        <IconButton
                                            aria-label="Auto Correct Colors"
                                            icon={<FaMagic />}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            onClick={() => alterEffect('lighting')}
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                        />
                                    </Tooltip>
                                </>
                            )}
                            {(!enhancedShadows && isMerged) && (
                                <>
                                    <Tooltip label="Auto Correct Shadows">
                                        <IconButton
                                            aria-label="Auto Correct Shadows"
                                            icon={<LuCloudFog />}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                            onClick={() => alterEffect('shadows')}
                                        />
                                    </Tooltip>
                                </>
                            )}

                            {(!isMerged && !hasMask) && (
                                <>
                                    <Tooltip label="Add Mask Layer" aria-label="Add Mark Layer Tooltip">
                                        <IconButton
                                            icon={<FaMask />}
                                            aria-label="Add Mark Layer"
                                            onClick={() => handleAddLayer('mask', '')}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                        />
                                    </Tooltip>
                                    <Tooltip label="Add Image Layer" aria-label="Add Image Layer Tooltip">
                                        <IconButton
                                            icon={<FaImage />}
                                            aria-label="Add Image Layer"
                                            onClick={() => handleAddLayer('image', '')}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                        />
                                    </Tooltip>
                                    <Tooltip label="Reset Positions" aria-label="Reset Positions">
                                        <IconButton
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                            icon={<BiRefresh />} aria-label="Reset Positions" onClick={() => resetLayers()} />
                                    </Tooltip>
                                    {image && image.layers?.length > 1 && (
                                        <Tooltip label="Merge Layers" aria-label="Merge Layers">
                                            <IconButton
                                                size="lg"
                                                variant="ghost"
                                                color="primary.0"
                                                _hover={{ opacity: 0.8 }}
                                                transition="opacity 0.2s"
                                                icon={<MdMerge />} aria-label="Merge Layers" onClick={() => handleMergeLayers()} />
                                        </Tooltip>
                                    )}
                                </>
                            )}

                            {(image && image.layers.length === 1 && (image.layers[0].other_values?.backgroundRemoved || isMerged)) && (
                                <>
                                    <Tooltip label="Save New Image">
                                        <IconButton
                                            aria-label="Save New Image"
                                            icon={<FaSave />}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                            onClick={onSaveOpen}
                                        />
                                    </Tooltip>
                                </>
                            )}

                            {hasMask && (
                                <Tooltip label="Activate Mask">
                                    <IconButton
                                        aria-label="Activate Mask"
                                        icon={<FaArrowAltCircleUp />}
                                        size="lg"
                                        variant="ghost"
                                        color="primary.0"
                                        _hover={{ opacity: 0.8 }}
                                        transition="opacity 0.2s"
                                        onClick={onMaskActivateOpen}
                                    />
                                </Tooltip>
                            )}

                            <Tooltip label="Reset back to original">
                                <IconButton
                                    aria-label="Reset back to original"
                                    icon={<DeleteIcon />}
                                    size="lg"
                                    variant="ghost"
                                    color="primary.0"
                                    _hover={{ opacity: 0.8 }}
                                    transition="opacity 0.2s"
                                    onClick={() => alterEffect('original')}
                                />
                            </Tooltip>


                        </HStack>
                        {!isMerged && image && !hasMask && image.layers && image.layers.map((layer: any, index: number) => (
                            <Box key={index} width="100%">
                                <Tooltip label={layer.type === 'image' ? `Image Layer ${index}` : `Text Layer ${index}`}>
                                    <Button
                                        aria-label={layer.type === 'image' ? `Image Layer ${index}` : `Text Layer ${index}`}
                                        bg={activeLayer?.public_id === layer.public_id ? 'gray.500' : 'gray.600'}
                                        onClick={() => setActiveLayer(layer)}
                                        cursor="pointer"
                                        width="100%"
                                        textAlign="center"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mb={2}
                                    >
                                        {layer.type === 'image' ? <FaImage /> : layer.type === 'text' ? <FaTextHeight /> : <FaMask /> }
                                        Position {layer.z_index}
                                    </Button>
                                </Tooltip>
                                {activeLayer?.public_id === layer.public_id && (
                                    <LayerToolbar
                                        layer={layer}
                                        onClone={() => cloneLayer(layer.public_id)}
                                        onDelete={() => deleteLayer(layer.public_id)}
                                        onRmBg={removeBackground}
                                        onUpdate={(params: any) => updateLayer(params)}
                                    />
                                )}
                            </Box>
                        ))}
                        {(image && image.layers.length && !hasMask) && (
                            <Button
                                onClick={() => handleDownload()}
                                bg="gray.600"
                                color="primary.0"
                                _hover={{ bg: "gray.600", color: "white" }}
                            >
                                Download Image
                            </Button>
                        )}
                    </VStack>
                </Box>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose} size="full">
                <ModalContent p={4} bg="gray.800" color="primary.0">
                    <ModalCloseButton />
                    <GallerySelect onImageSelect={imageSelected} selectText="Import Image" />
                </ModalContent>
            </Modal>

            <Modal isOpen={isSaveOpen} onClose={onSaveClose}>
                <ModalContent p={4} bg="gray.800" color="primary.0">
                    <ModalCloseButton />
                    <Box minH={250}>
                        <Text mb={4}>
                            Please select a gallery and the image will save to that gallery. If you have not created
                            a gallery yet, please create one first.
                        </Text>
                        <Text as={"small"}>
                            Click 'Select Gallery'
                        </Text>
                        <Gallery
                            isVisible={isSaveOpen}
                            onClose={onSaveClose}
                            isSelect={true}
                            onListSelect={(publicId) => handleSave(publicId)}
                        />
                    </Box>
                </ModalContent>
            </Modal>

            <Modal isOpen={isMaskActivateOpen} onClose={onMaskClose}>
                <ModalOverlay />
                <ModalContent bg="gray.800" color="primary.0">
                    <ModalHeader>Mask Transformation</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <Text>
                                Please specify how you would like to transform the masked area.
                                Your instruction should clearly describe the desired modification
                                (e.g., "Change the shirt color to deep purple" or "Add a subtle
                                texture to the masked region").
                            </Text>
                            <Textarea
                                placeholder="Enter your transformation instruction..."
                                size="md"
                                resize="vertical"
                                min-height="100px"
                                value={maskPrompt}
                                onChange={(e) => setMaskPrompt(e.target.value)}
                            />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={handleMaskActivation}
                            isLoading={loading}
                        >
                            Transform
                        </Button>
                        <Button variant="ghost" color="primary.0" onClick={onMaskClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ModifyImage;