import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "~/store";
import {useEffect, useState} from "react";
import {addImage, fetchGalleries, Gallery as GalleryProps} from "~/store/gallerySlice.ts";
import {Box, Button, Flex, Grid, GridItem, IconButton, Image, Input, Text, useToast, VStack} from "@chakra-ui/react";
import {FaArrowLeft, FaFolder, FaImage} from "react-icons/fa";
import Gallery from "~/apps/backend/components/modals/Gallery.tsx";
import {GalleryImage} from "~/utils/types.ts";

interface GallerySelectProps {
    onImageSelect: (image: GalleryImage) => void;
    selectText: string;
}

const GallerySelect = ({ onImageSelect, selectText }: GallerySelectProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showAddGallery, setShowAddGallery] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState<GalleryProps | null>(null);
    const galleries = useSelector((state: RootState) => state.gallery.galleries);
    const loading = useSelector((state: RootState) => state.gallery.loading);
    const toast = useToast();

    useEffect(() => {
        dispatch(fetchGalleries());
    }, []);

    const handleSelectGallery = (gallery: GalleryProps) => {
        setSelectedGallery(gallery);
    };

    const resetGallerySelection = () => {
        setSelectedGallery(null);
    }

    const handleUploadImages = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if(!files) {
            return;
        }
        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });
        dispatch(addImage({ formData, galleryId: selectedGallery?.public_id! })).unwrap().then((updatedGallery) => {
            setSelectedGallery(updatedGallery);
            toast({
                title: "Images uploaded",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }).catch(() => {
            toast({
                title: "Failed to upload images",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    };


    return (
        <Box>
            <Text as="h1" mb={50}>Galleries</Text>

            {selectedGallery === null ? (
                <>
                    <Box textAlign="right">
                        <Button
                            onClick={() => {setShowAddGallery(true)}}
                            bg="gray.600"
                            color="primary.0"
                            mb={4}
                            disabled={loading}
                            _hover={{ bg: "gray.600", color: "white" }}
                        >
                            New Gallery
                        </Button>
                    </Box>
                    <Grid mt={4} templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
                        {galleries.map((gallery) => (
                            <GridItem key={gallery.public_id}>
                                <Button
                                    onClick={() => handleSelectGallery(gallery)}
                                    variant="ghost"
                                    width="100%"
                                    minH={120}
                                    color="gray.500"
                                    p={0}
                                    _hover={{ bg: "gray.600", color: "white" }}
                                >
                                    <Box p={2} width="100%">
                                        <VStack>
                                            <FaFolder size="50px" />
                                            <span>{gallery.name}</span>
                                        </VStack>
                                    </Box>
                                </Button>
                            </GridItem>
                        ))}
                    </Grid>
                </>
            ) : (
                <>
                    <Flex justifyContent="space-between" alignItems="center" >
                        <IconButton
                            icon={<FaArrowLeft />}
                            aria-label="Back"
                            onClick={() => resetGallerySelection()}
                            mr={2}
                        />
                        <Input
                            type="file"
                            multiple
                            onChange={handleUploadImages}
                            display="none"
                            id="upload-images"
                        />
                        {!selectedGallery.is_collection && (
                            <Button
                                as="label"
                                htmlFor="upload-images"
                                colorScheme="blue"
                                disabled={loading}
                            >
                                Upload Images
                            </Button>
                        )}
                    </Flex>
                    {selectedGallery.images.length === 0 ? (
                        <Flex textAlign="center" mt={10} alignItems="center" direction="column">
                            <FaImage size="50px" color="gray" />
                            <Text mt={4} fontSize="x-large" color="gray.500">
                                No images found for {selectedGallery.name}
                            </Text>
                        </Flex>
                    ) : (
                        <Grid mt={14} templateColumns="repeat(auto-fill, minmax(100px, 1fr))" gap={4}>
                            {selectedGallery.images.map((image) => (
                                <GridItem key={`${selectedGallery.public_id}_${image.public_id}`} position="relative">
                                    <Image
                                        src={image.image_url}
                                        alt="Gallery Image"
                                        boxSize="100px"
                                        objectFit="cover"
                                    />
                                    <Box
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        width="100%"
                                        height="100%"
                                        bg="rgba(0, 0, 0, 0.5)"
                                        opacity="0"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        transition="opacity 0.3s"
                                        _hover={{ opacity: 1 }}
                                    >
                                        <Button
                                            colorScheme="whiteAlpha"
                                            variant="outline"
                                            onClick={() => onImageSelect(image)}
                                        >
                                            {selectText}
                                        </Button>
                                    </Box>
                                </GridItem>
                            ))}
                        </Grid>
                    )}
                </>
            )}

            <Gallery isVisible={showAddGallery} onClose={() => setShowAddGallery(false)} />
        </Box>
    )
}

export default GallerySelect;