import { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    Text,
    Button,
    Alert,
    AlertIcon,
    Spinner,
    SimpleGrid,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast,
    IconButton,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchCollectionDetail,
    fetchTrainingStatus,
    trainModel,
    uploadImage,
    deleteImage,
    generateAiInfluencer
} from '~/store/collectionSlice';
import { RootState, AppDispatch } from '~/store';
import { FaCoffee, FaTimes } from 'react-icons/fa';
import {Center} from "@chakra-ui/icons";

const ModelDetail = () => {
    const { public_id } = useParams<{ public_id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { currentCollection, loading } = useSelector((state: RootState) => state.collections);
    const [trainingLoading, setTrainingLoading] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isDeleteModalOpen,
        onOpen: onOpenDeleteModal,
        onClose: onCloseDeleteModal,
    } = useDisclosure();
    const toast = useToast();
    const [selectedImageId, setSelectedImageId] = useState<number | null>(null);

    useEffect(() => {
        if (public_id) {
            dispatch(fetchCollectionDetail(public_id));
        }
    }, [dispatch, public_id]);

    useEffect(() => {
        if (currentCollection && ['active', 'processing'].includes(currentCollection.training_status)) {
            handleRefreshStatus();

            const id = setInterval(() => {
                handleRefreshStatus();
            }, 2 * 60 * 1000);
            return () => clearInterval(id);
        }
    }, [dispatch, currentCollection, public_id]);

    const handleRefreshStatus = () => {
        if (public_id) {
            dispatch(fetchTrainingStatus(public_id));
        }
    };

    const handleTrainModel = async () => {
        // if collection is ai influencer and image count < 10 show error
        if (currentCollection?.training_type === 'ai influencer' && currentCollection.images.length < 6) {
            toast({
                title: "Error",
                description: "Use the generate AI influencer positions button until you have at least 6 images.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        if (public_id && !trainingLoading) {
            try {
                setTrainingLoading(true);
                await dispatch(trainModel({ public_id })).unwrap();
            } catch (error) {
                if ((error as any)?.error.includes("billing restrictions")) {
                    // Show specific error message for 403 error
                    navigate('/c-panel/billing?alt=lmtc');
                } else {
                    const errorMessage = ((error as any)?.error?.includes("billing restrictions"))
                        ? "You have used your model quota for this month. Please contact support."
                        : (error as any)?.error || "Failed to train model. Please try again later.";
                    toast({
                        title: "An error occurred",
                        description: errorMessage,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } finally {
                setTrainingLoading(false);
            }
        }
    };

    const handleImageUpload = async () => {
        if (imageFile && public_id) {
            const formData = new FormData();
            formData.append('collection', public_id);
            formData.append('image_file', imageFile);

            try {
                await dispatch(uploadImage(formData)).unwrap();
                setImageFile(null);
                onClose();
            } catch (error) {
                toast({
                    title: "An error occurred",
                    description: "Failed to upload image. Please try again or contact support.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const handleDeleteImage = async () => {
        if (selectedImageId) {
            try {
                await dispatch(deleteImage(selectedImageId)).unwrap();
                onCloseDeleteModal();
                setSelectedImageId(null);
                toast({
                    title: "Image deleted",
                    description: "The image was successfully deleted.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } catch (error) {
                toast({
                    title: "Error deleting image",
                    description: "Failed to delete image. Please try again later.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    if (!currentCollection) {
        return <Text>No model found</Text>;
    }

    const handleGenerateImagesClick = () => {
        if (!currentCollection) {
            return;
        }
        navigate(`/c-panel/generate-images`);
    };

    const handleGenerateAIInfluencerPositions = () => {
        if (!currentCollection) {
            return;
        }
        dispatch(generateAiInfluencer(currentCollection.public_id)).unwrap().then(() => {
            toast({
                title: "AI Influencer Positions Generated",
                description: "AI Influencer positions have been generated. You can now add images.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            if(public_id) {
                dispatch(fetchCollectionDetail(public_id));
            }
        }).catch(() => {
            toast({
                title: "Error",
                description: "Failed to generate AI Influencer positions. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }

    return (
        <Box p={6} bg="gray.800" minH="100vh" shadow="md" color="primary.0">
            {loading && (
                <Center
                    position="fixed"
                    inset={0}
                    bg="blackAlpha.700"
                    backdropFilter="blur(8px)"
                    zIndex={9999}
                    flexDirection="column"
                    gap={4}
                >
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.600"
                        color="primary.0"
                        size="xl"
                    />
                    <Text color="primary.0" fontSize="xl">
                        Loading...
                    </Text>
                </Center>
            )}
            <Heading as="h1" size="xl">{currentCollection.name}</Heading>
            <Text mt={2}>{currentCollection.description || 'No description available'}</Text>
            <Text mt={1} fontSize="sm" color="gray.500">
                Created at: {new Date(currentCollection.created_at).toLocaleDateString()}
            </Text>

            {/* Training Status Messages */}
            {currentCollection.training_status === 'succeeded' && (
                <Alert status="success" mt={4} bg="green.700" color="primary.0">
                    <AlertIcon />
                    This model has already been trained.
                    <Button onClick={handleGenerateImagesClick} colorScheme="green" ml={4}>
                        Start Generating Images
                    </Button>
                </Alert>
            )}

            {['processing', 'active'].includes(currentCollection.training_status) && (
                <Alert status="info" mt={4} bg="blue.700" color="primary.0">
                    <AlertIcon />
                    Your custom AI model is currently in training. Training usually takes around 30 minutes. Enjoy a coffee
                    <Text as="span" ml={{ base: "0.5", md: "1" }} mr={{ base: "0.5", md: "1" }} verticalAlign="middle">
                        <FaCoffee style={{ display: "inline-block", verticalAlign: "middle" }} size="1em" />
                    </Text>
                    while you wait.
                </Alert>
            )}

            {['failed'].includes(currentCollection.training_status) && (
                <Alert status="error" mt={4} bg="red.700" color="primary.0">
                    <AlertIcon />
                    Training failed. Please try again.
                    <Button onClick={handleTrainModel} colorScheme="red" ml={4}>
                        Retry Training
                    </Button>
                </Alert>
            )}

            {['Not Trained'].includes(currentCollection.training_status) && (
                <Button mt={4} colorScheme="blue" isDisabled={currentCollection.images.length < 1} onClick={handleTrainModel}>
                    Train Model
                </Button>
            )}

            {currentCollection.training_type === 'ai influencer' && (
                <Alert status="info" mt={4} bg="blue.700" color="primary.0">
                    <AlertIcon />
                    This model is an AI Influencer model. It is designed to generate images of people.
                </Alert>
            )}

            {currentCollection.training_type === 'product' && (
                <Alert status="info" mt={4} bg="blue.700" color="primary.0">
                    <AlertIcon />
                    This model is a product model. It is designed to generate images of products.
                </Alert>
            )}

            {currentCollection.training_type === 'other' && (
                <Alert status="info" mt={4} bg="blue.700" color="primary.0">
                    <AlertIcon />
                    This model is a general model. It is designed to generate images of general subjects.
                    People, animals, clothing, etc.
                </Alert>
            )}

            {/* Model Images */}
            <Heading as="h3" size="md" mt={6}>Images</Heading>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4} mt={4}>
                {currentCollection.images.map((image) => (
                    <Box key={image.id} maxW="100%" bg="gray.700" p={2} position="relative" borderRadius="md">
                        <img src={image.image_file} alt={image.caption} style={{ width: '100%', height: 'auto' }} />
                        {['Not Trained', 'failed'].includes(currentCollection.training_status) && (
                            <IconButton
                                aria-label="Delete Image"
                                icon={<FaTimes />}
                                colorScheme="red"
                                position="absolute"
                                top="2"
                                right="2"
                                onClick={() => {
                                    setSelectedImageId(image.id);
                                    onOpenDeleteModal();
                                }}
                            />
                        )}
                    </Box>
                ))}
            </SimpleGrid>

            {/* Add Image Button */}
            {(currentCollection.training_status !== 'succeeded' && currentCollection.images.length < 50) && (

                <>
                    {currentCollection?.training_type !== 'ai influencer' && (
                        <Button colorScheme="blue" mt={6} onClick={onOpen}>
                            Add Image
                        </Button>
                    )}

                    {(currentCollection?.training_type === 'ai influencer') && (
                        <Box>
                            {currentCollection.images.length < 1 && (
                                <Button colorScheme="blue" mt={6} onClick={onOpen}>
                                    Add Image
                                </Button>
                            )}

                            {(currentCollection.images.length > 0 && currentCollection.images.length < 10)  && (
                                <Button colorScheme="blue" mt={6} onClick={handleGenerateAIInfluencerPositions}>
                                    Generate AI Influencer Positions
                                </Button>
                            )}
                        </Box>
                    )}
                </>
            )}

            {(currentCollection.training_status !== 'succeeded' && currentCollection.images.length > 49) && (
                <Alert status="info" mt={6} bg="blue.700" color="primary.0">
                    <AlertIcon />
                    Maximum number of training images reached for this model.
                </Alert>
            )}

            {/* Add Image Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg="gray.800" color="primary.0">
                    <ModalHeader>Add Image to Collection</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
                            bg="gray.700"
                            color="primary.0"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleImageUpload} isDisabled={!imageFile}>
                            Upload Image
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Confirm Delete Modal */}
            <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Delete</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete this image?
                        <Text as="span" color="red"> WARNING: </Text> This action cannot be undone.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={handleDeleteImage} mr={3}>
                            Delete
                        </Button>
                        <Button variant="ghost" onClick={onCloseDeleteModal}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ModelDetail;
